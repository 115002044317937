import "@fortawesome/fontawesome-free/js/all.min"

import VueRouter from 'vue-router';
import VueApollo from 'vue-apollo'
import ApolloClient from 'apollo-boost'
import ShopCategoryComponent from './components/ShopCategoryComponent';
import Vue2Filters from 'vue2-filters';
import DropdownMenu from "v-dropdown-menu/vue2";
// import BootstrapVue from "bootstrap-vue";
import CartComponent from "./components/CartComponent";
import store from './store';
import 'tw-elements';
import StorePoliciesComponent from "./components/StorePoliciesComponent";
import CaveRewardsComponent from "./components/CaveRewardsComponent";
import AccountViewComponent from "./components/AccountViewComponent";
import WineShopComponent from "./components/WineShopComponent";
// import './modules.js'
// import './SlideShowBob.js'
// import './nav.js'
// import "./Expand.js"
// import "./History.js"
// import "./media-nav.js"
window._ = require('lodash');
// try {
//     window.$ = window.jQuery = require('jquery');
//     window.Popper = require('popper.js').default;
//     require('bootstrap');
// } catch (e) {
// }



window.Vue = require('vue');
Vue.use(Vue2Filters)
Vue.use(VueApollo)
Vue.use(VueRouter)
Vue.use(DropdownMenu);
// Vue.use(BootstrapVue);
// Vue.config.devtools = true

window.axios = require('axios')
window.axios.defaults.headers.common['X-Csrf-Token'] = window.csrfTokenValue;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';


const apolloClient = new ApolloClient({
    // You should use an absolute URL here
    uri: process.env.MIX_ABSOULTE_URL
})

const router = new VueRouter({
    mode: 'history',
    routes: [
        // { path: '/wineshop/', component: WineShopComponent},
        { path: '/wineshop/policies', component: StorePoliciesComponent },
        // { path: '/wineshop/account', component: AccountViewComponent},
        { path: '/wineshop/mycave-rewards', component: CaveRewardsComponent },
        // { path: '/wineshop/rewards', component: RedeemRewardsComponent},
        { path: '/wineshop/cart', component: CartComponent },
        { path: '/wineshop/:type', component: ShopCategoryComponent, props: true },
        // { path: '/wine/:type', component: ShopCategoryComponent, props: true },
        // {
        //     name: 'Riesling',
        //     path: '/wineshop/riesling',
        //     props: {
        //         url: 'riesling',
        //         code: 'R',
        //         title: 'Riesling'
        //     },
        //     component: ShopCategoryComponent
        // },
        // {
        //     name: 'Chardonnay',
        //     path: '/wineshop/chardonnay',
        //     props: {
        //         url: 'chardonnay',
        //         code: 'C',
        //         title: 'Chardonnay'
        //     },
        //     component: ShopCategoryComponent
        // },
    ]
})

// Vue.component('test', require('./components/HomePageSwaggerTest').default);
// Vue.component('shop-component', require('./components/ShopComponent').default);
// Vue.component('product-card-component', require('./components/ProductCardComponent').default);
// Vue.component('reward-card-component', require('./components/Rewards/RewardCardComponent').default);
// Vue.component('reward-modal-component', require('./components/Rewards/RewardModalComponent').default);
// Vue.component('product-modal-component', require('./components/ProductModalComponent').default);
// Vue.component('cart-item-component', require('./components/CartItemComponent').default);
// Vue.component("cart-nav-component", require('./components/CartNavComponent').default);
// Vue.component("rewards-component", require('./components/Rewards/RewardsComponent').default);
// Vue.component("custom-order-component", require('./components/club/CustomOrderComponent').default);
// Vue.component("custom-order-edit-component", require('./components/club/CustomOrderEditComponent').default);
Vue.component("our-wines", require('./components/OurWinesComponent.vue').default);
Vue.component("tech-component", require('./components/TechDataComponent').default);
Vue.component("tech-breadcrumbs", require('./components/TechDataBreadcrumbsComponent').default);
Vue.component("reviews", require('./components/ReviewsAccolades').default);
Vue.component("accordion", require('./components/CustomAccordion').default);
Vue.component("weddings", require('./components/weddingGallery').default);
Vue.component("experience", require('./components/ExperienceComponent').default);
Vue.component("hero", require('./components/HomeSlider.vue').default);
// Vue.component("emma", require('./components/MyEmmaComponent').default);
// Vue.component('country-province-select', require('./components/address/CountrySelectComponent').default);

const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
});

const aoo = new Vue({
    el: '#new-app',
    router,
    store,
    apolloProvider,
});
