<template>

  <div v-if="heroSlides">

    <div v-if="heroSlides.entries.length >= 1" class="reviews h-screen overflow-hidden flex flex-wrap items-center inner-hero h-screen overflow-hidden relative">
      <div v-for="(data, index) in heroSlides.entries" v-if="indexDisplayed === index" class="text-div h-screen overflow-hidden w-full grid justify-items-center content-center">
        <h2 class="text-font-body  uppercase text-3xl md:text-6xl lg:text-8xl w-full text-center m-5">{{data.heroSection[0].heroTitle}}
        </h2>
        <div class="w-full  text-center text-font-body mt-3 md:mt-5">
          									<p class="text-base px-2 tracking-wider md:text-27 uppercase">
            										{{data.heroSection[0].subtitle}}
            									</p>
         					</div>
        <div id="small" class="w-3/4  text-center text-font-body my-1 md:m-5">
          		<p class=" md:text-27 w-full">{{data.heroSection[0].paragraph}}</p>
        </div>

        <div class="m-5  flex justify-center flex-wrap md:flex-nowrap">
          									<div class="white-banner mx-10 my-1  w-5/8 md:w-full" style="position: relative;">
            										<a :href="data.heroSection[0].leftButtonLink">
              											<img class="white-banner" src='/assets/svg/banner-white.svg'>
              										<div class="text-font-grey button-style">
               												{{data.heroSection[0].leftButtonName}}
                											</div>
                                </a>
                              									</div>
          <div class="white-banner mx-10 my-1 w-5/8 md:w-full" style="position: relative;">
            										<a :href="data.heroSection[0].rightButtonLink">
              											<img class="white-banner" src='/assets/svg/banner-white.svg'>
              											<div class="text-font-grey button-style uppercase">
                													{{ data.heroSection[0].rightButtonName }}
                												</div>
              											</a>
            										</div>

        </div>



        <img v-if="data.heroSection[0].heroTitle === 'Origins'" class=" -z-10 object-[30%_25%] bg-black absolute brightness-85" style="width: 100%; height: 100vh;
object-fit: cover;" :src="data.photo[0].url">
        <img v-else class="bg-black absolute brightness-85 -z-10" style="width: 100%; height: 100vh;
object-fit: cover;" :src="data.photo[0].url">




        <div class="flex justify-center basis-full pt-4 pb-4 lg:pb-8 absolute bottom-0 " style="z-index: 60; ">
          <a v-for="(item, index) in heroSlides.entries" class="mx-2">
            <svg v-if="indexDisplayed === index" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">
              <g class=" svg circle-color" fill="#fff" stroke="#707070" stroke-width="1">
                <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
                <circle cx="6.5" cy="6.5" r="6" fill="none"/>
              </g>
            </svg>
            <svg @click="changeReview(index)" v-else xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewbox="0 0 13 13">
              <g class="svg"  fill="#fff" stroke="#707070" stroke-width="1">
                <circle cx="6.5" cy="6.5" r="6.5" stroke="none"/>
                <circle cx="6.5" cy="6.5" r="6" fill="none"/>
              </g>
            </svg>
          </a>
        </div>
      </div>

    </div>


  </div>
</template>

<script>
import Checkbox from 'vue-material-checkbox'
import gql from "graphql-tag";
export default {
  name: "heroSlider",
  props: ['image'],
  components: { Checkbox },
  apollo: {
    heroSlides: {
      query: gql`query GetHeroes {
        entries(section: "heroImages") {
    ... on heroImages_heroImages_Entry {
      photo {
        url
      }
       heroSection {
        ... on heroSection_image_block_BlockType {
          id
          heroTitle
          enabled
          rightButtonLink
          leftButtonName
          leftButtonLink
          paragraph
          rightButtonName
          subtitle
        }
      }

    }
  }
  }`,
      update: data => data,
    },
  },


  data() {
    return {
      indexDisplayed: 0,
      dummyData: [
        {
          title: "2017 Riesling CSV, Beamsville Bench, Estate Grown",
          subtitle: "Top Rated Canadian Riesling",
          desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
          rating: "94"
        },
        {
          title: "Review 2",
          subtitle: "Top Rated Canadian Riesling",
          desc: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
          rating: "96"
        }
      ],
      timer: null,
      Reviews: [],
      promos: [],
    }},
  mounted: function () {
    this.timer = setInterval(() => {
      if(this.indexDisplayed === (this.heroSlides.entries.length - 1))
        this.indexDisplayed = 0;
      else {
        this.indexDisplayed++;
      }
    }, 10000)
  // }, 100000) // 10 seconds on the slider
  },
  methods: {
    changeReview(index) {
      this.indexDisplayed = index;
    },
    // promotionDisplay: function (sku){
    //
    //   console.log(sku)
    //   console.log(this.promos)
    //
    //
    //   let currentDate = new Date().toLocaleDateString();
    //   if(this.promos.entries) {
    //
    //     for (let i = 0; i < this.promos.entries.length; i++) {
    //       let displayUntil = this.promos.entries[i].displayUntil;
    //       console.log(currentDate);
    //       console.log(displayUntil);
    //
    //       if (this.promos.entries[i].promoSku === sku.reviewSku && currentDate <= displayUntil.substring(0, currentDate.length)) {
    //         sku.field_promoMessage_xyvkrwsy = this.promos.entries[i].promoMessage;
    //         sku.field_promoType_qkkxzmgw = this.promos.entries[i].promoType;
    //         console.log("great success")
    //         return true;
    //       }
    //     }
    //   }
    //   console.log('No Success')
    //   return false;
    //
    // },


  },
  computed: {

  }
}
</script>
<style>

</style>