<template>
  <div class="w-full">

    <div v-if="venue === 'all'">
      <div v-for="text in extras.entries">

        <p class="w-1/2 mx-auto my-10" v-if="text.title === currentExperience " v-html="text.c7ExperienceIntro"> </p>

      </div>
    </div>

    

    <!--<dropdown-menu with-dropdown-closer>
      <template #trigger>

        <button @click="consoleMe()"  class="capitalize">{{currentExperience}} &or; </button>
      </template>

<!--    <template #header> Dropdown Header </template>-->

  <!--    <template #body>
        <ul>
          <li v-for="i in status" :key="i">
            <a v-if="i.toUpperCase() !== currentExperience.toUpperCase()" @click="changeFilter(i)" dropdown-closer  href="javascript:void(0);">{{ i }}</a>

          </li>

        </ul>
      </template>
      -->

<!--     <template #footer> Dropdown Footer </template>-->
<!--    </dropdown-menu> -->

      <div v-if="venue === 'all'" class="buttons hidden lg:flex flex-wrap md:flew-nowrap w-3/4  justify-around" style="margin:0 auto;"><div @click="changeFilter('Winery')"  class="white-banner  lg:scale-75" style="position: relative;"><a><img src="/assets/svg/estate.svg" class="white-banner"> <div class="text-white w-full text-14 button-style"> Winery
      </div></a></div> <div @click="changeFilter('Vineyard')" class="white-banner  lg:scale-75" style="position: relative;"><a><img src="/assets/svg/dolomite.svg" class="white-banner"> <div class="text-white w-full text-14 button-style">Vineyard
      </div></a></div>
        <div class="white-banner  lg:scale-75" style="position: relative;"><a href="/corperate-experiences"><img src="/assets/svg/proprietary.svg" class=" white-banner"> <div class="text-white w-full text-14 button-style">Private Experiences
      </div></a></div>
      </div>




  <div v-if="experiences.entries" >
  <div class="expStyle lg:w-screen lg:ml-[calc(50%-50vw);]" v-for="experience in experiences.entries">
    <div v-if="currentExperience === 'All Experiences' || currentExperience.toUpperCase() === experience.location.toUpperCase()" class="experience-styling lg:w-[55%] m-auto  flex flex-wrap md:flex-nowrap  my-5 min-h-[25vh] ">


      <div v-bind:class="{altback: secondaryBG}" class="lg:w-1/2  bg-[#eaeaea]">
        
        <img class="mobile-experience lg:hidden"  :src="experience.photo[0].url">
<!--width screen - 20% height auto-->


        <div v-if="experience.location === 'winery'" id="wineryButton" @click="changeFilter(experience.location)" class="absolute mt-5 px-4 py-2 capitalize text-center bg-[#80aabe] text-white " >
          <span>{{ experience.location }}</span>
        </div>
        <div v-else id="vineyardButton"   @click="changeFilter(experience.location)" class="absolute mt-5 px-4 py-2 capitalize text-center text-white bg-[#B6A493]" >
            <span>{{ experience.location }}</span>
        </div>


        <div class="mt-20 px-16">
          <h3>{{experience.title}}</h3>

        <p v-html=experience.description></p>
        </div>

        <div v-if="experience.time && experience.cost" class="cost-time mt-5 px-16">
           <strong> {{experience.time }} |
            {{experience.cost }} </strong>
        </div>


            <div class="buttons flex w-full px-16 my-10">
                  <div class="relative">
                      <a :href="'/experience/' + experience.slug ">
                          <img src='/assets/svg/brown-banner.svg'>
                          <div class="uppercase text-white w-full flex absolute top-0 bottom-0 items-center justify-center">Details</div>
                      </a>
                  </div>
              </div>

        </div>




        <!-- <div class="craft-c7-styling">
          <div class="c7-reservation-availability" v-bind:data-reservation-type-slug="experience.c7Slug"></div>
        </div> -->






      <div v-if="experience.photo[0]" class="lg:w-1/2 side-photo" v-bind:style="{ 'background-image': 'url(' + experience.photo[0].url + ')'  } ">





      </div>
  </div>

  </div>
  </div>
  </div>

</template>
<script>
import gql from "graphql-tag";
import "v-dropdown-menu/dist/vue2/v-dropdown-menu.css"
import DropdownMenu from "v-dropdown-menu/vue2"
export default {
  name: "ExperienceComponent",
  props: ['venue'],
  apollo: {
    experiences: {
      query: gql`query GetExperiences {
        entries(section:"experiencesC7") {
          ... on experiencesC7_default_Entry{
        slug  
        id
        c7Slug
        description
        photo {
            id
            url
        }
        location
        title
        cost
        fullText
        introText
        termsConditions
        time

  }
  }

      }`,
      update: data => data,
    },
    extras: {
      query: gql`query GetExtras {
        entries(section: "c7ExperienceExtras") {
          ... on c7ExperienceExtras_default_Entry{
           id
           c7ExperienceIntro
           title
  }
  }

      }`,
      update: data => data,
    },
  },
  data() {
    return {
      experiences: {},
      extras: {},

      status : ['All Experiences', 'Winery', 'Vineyard'],
      currentExperience : 'All Experiences',
      isOpen : '',
      secondaryBG : false,
      
    }

  },
  computed: {


  },

  methods: {
    consoleMe: function () {
    console.log('opened');


    },

    changeFilter: function(i) {
      console.log(i);
      if(this.venue === 'all') {
        this.currentExperience = i;
      }


    },

  },
  created() {
    console.log(this.venue)
    if(this.venue !== 'all') {
      this.secondaryBG = true;
    }

    if(this.venue === 'vineyard') {
      this.currentExperience = 'Vineyard'

    } else if(this.venue === 'winery') {
      this.currentExperience = 'Winery'
    }

  },

}

</script>
<style>
.v-dropdown-menu__container {
  background-color: transparent;
  border: 0px;
  position: relative;
}

#wineryButton:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: var(--blue);
  transition: all .35s;

}

#wineryBtton {
  position: relative;
}

#wineryButton:hover {
  color: #fff;
}

#wineryButton:hover:after {
  width: 100%;
}

#wineryButton span {
  position: relative;
  z-index: 2;
}

#vineyardButton:after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: #906e45;
  transition: all .35s;

}


#vineyardButton:hover {
  color: #fff;
}

#vineyardButton:hover:after {
  width: 100%;
}

#vineyardButton span {
  position: relative;
  z-index: 2;
}

.side-photo {
  background-size: cover;
  background-position: center center;
}

.mobile-experience {
  width: calc(100% - 2rem);
  margin: 0 auto;
  margin-top: 1rem;
}

.altback {
  background-color: white!important;
}
</style>
